import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import blogdata from './data/blogdata.json';
import Singleblogdata from './data/single-blogdata.json';
import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
import HomeV3 from './components/home-v3';
import HomeV4 from './components/home-v4';
import HomeV5 from './components/home-v5';
import Investment from './components/investment';
import Investor from './components/investor';
import UserPanel from './components/user-panel';
import Case from './components/case';
import CaseDetials from './components/case-details';
import About from './components/about';
import ErrorPage from './components/error-page';
import Faq from './components/faq';
import Privacy from './components/privacy';
import Contact from './components/contact';
import Blog from './components/blog';
import BlogLeftSidebar from './components/blog-left-sidebar';
import BlogrightSidebar from './components/blog-right-sidebar';
import BlogDetails from './components/blog-details';


class Root extends Component {
    render() {
        return(
            <Router>
                <HashRouter basename="/">
                <div>
                <Switch>
                    <Route exact path="/" component={HomeV2} />                    
                    <Route path="/home-v3" component={HomeV3} />
                    <Route path="/home-v4" component={HomeV4} />
                    <Route path="/home-v5" component={HomeV5} />
                    <Route path="/investment" component={Investment} />
                    <Route path="/investor" component={Investor} />
                    <Route path="/user-panel" component={UserPanel} />
                    <Route path="/case" component={Case} />
                    <Route path="/case-details" component={CaseDetials} />
                    <Route path="/about" component={About} />
                    <Route path="/error-page" component={ErrorPage} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/blog" component={Blog} />
                    <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
                    <Route path="/blog-right-sidebar" component={BlogrightSidebar} />
                    <Route path="/blog-details" component={BlogDetails} />
                </Switch>
                </div>
                </HashRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('investon'));
