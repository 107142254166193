import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class ContactInfo extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.banner

    return <div>
		  {/* contact area start */}
		  <div className="contact-area pd-bottom-85">
		    <div className="container">
		      <div className="row justify-content-center">
		        <div className="col-lg-8">
		          <div className="section-title text-center">
		            <h5 className="subtitle">Feel Free To Conatct Us</h5>
		            <h3 className="title">Contact Us</h3>
		            <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web</p>
		          </div>
		        </div>
		      </div>
		      <div className="row justify-content-center">
		        <div className="col-lg-4 col-md-6">
		          <div className="single-contact text-center bottom-left-radius-0">
		            <div className="icon">
		              <img src={publicUrl+"assets/img/icon/home.png"} alt="icon" />
		            </div>
		            <h5>Our Head Office</h5>
		            <p>123 - sed do eiusmod tempor NewYork U.S.A</p>
		          </div>
		        </div>
		        <div className="col-lg-4 col-md-6">
		          <div className="single-contact text-center bottom-left-radius-0">
		            <div className="icon">
		              <img src={publicUrl+"assets/img/icon/envelope.png" }alt="icon" />
		            </div>
		            <h5>E-mail</h5>
		            <p>example@gmail.com</p>
		            <p>yourdomain@gmail.com</p>
		          </div>
		        </div>
		        <div className="col-lg-4 col-md-6">
		          <div className="single-contact text-center bottom-left-radius-0">
		            <div className="icon">
		              <img src={publicUrl+"assets/img/icon/phone.png"} alt="icon" />
		            </div>
		            <h5>Phone</h5>
		            <p>+1422(678975447)</p>
		            <p>+1322(678975447)</p>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		  {/* contact area end */}
		  {/* contact-map area start */}
		  <div className="main-map">
		    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47702.39047195484!2d-91.5744685628408!3d41.6471117474476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87e441c16a208817%3A0x6d711867870582b0!2sIowa%20City%2C%20IA%2C%20USA!5e0!3m2!1sen!2sbd!4v1581251336408!5m2!1sen!2sbd" style={{border: 0}} allowFullScreen />
		  </div>
		  {/* contact-map area end */}
		</div>


        }
}

export default ContactInfo