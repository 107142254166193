import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class ProfitArea extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.profit

    return <div className="check-profit-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <form>
              <div className="title text-center">
                <h5>Calculate Your Profit</h5>
              </div>
              <div className="form-group">
                <label>Enter Ammount :</label>
                <input type="number" placeholder="$20" className="form-control" />
              </div>
              <div className="form-group">
                <label>Total Profit :</label>
                <input type="number" placeholder="$16" className="form-control" />
              </div>
              <div className="form-group">
                <label>Net Profit :</label>
                <input type="number" placeholder="$06" className="form-control" />
              </div>
            </form>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="payment-method-wrap">
              <h2>{ data.title }</h2>
              <p>{ data.content }</p>
              <ul className="payment-card">
              { data.items.map( (  item, i)=> 
                <li key={ i } >
                  <a href="#"><img src={publicUrl+item.img }alt="img" /></a>
                </li>
                ) }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

        }
}

export default ProfitArea