import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class UserPanel extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.banner

    return <div className="user-panel">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-3">
		        <span className="user-panel-sidebar-btn">Sidebar menu<i className="fa fa-bars" /></span>
		        <ul className="user-panel-sidebar bg-gradient">
		          <li className="active"><a href="#">Account Overview</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Max Deposit</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Withdraw Fund</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Deposit List</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Deposit History</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Withdraw History</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Earnings History</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Referral Link</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Account Settings</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Security Settings</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Referral Link</a></li>
		          <li><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true" />Sign Out</a></li>
		        </ul>
		      </div>
		      <div className="col-lg-9">
		        <div className="user-panel-wrapper">
		          <div className="user-info-area bg-panel">
		            <div className="user-info-header">
		              <ul className="action-list">
		                <li><a href="#"><i className="fa fa-search" /></a></li>
		                <li><a href="#"><i className="fa fa-arrows-alt" /></a></li>
		                <li><a href="#"><i className="fa fa-bell-o" /></a></li>
		              </ul>
		            </div>
		            <div className="user-thumb"><img src={publicUrl+"assets/img/team/01.png"} alt="image" /></div>
		            <div className="user-content">
		              <h3 className="user-name">Hello , I am Steinkellner</h3>
		              <ul className="user-details-list">
		                <li>Registration : Jan - 14-2020</li>
		                <li>Last Deposit : $750 Jan - 15 - 2020</li>
		                <li>Last Withdraw : $300 March - 20 - 2020</li>
		              </ul>
		            </div>
		          </div>
		          {/* user-info-area end */}
		          <div className="row statitics-item-area mb-none-30">
		            <div className="col-lg-4">
		              <div className="statitics-item mb-30">
		                <h6 className="title">Total Balance</h6>
		                <span className="stat-amount color-1">$125.00</span>
		                <div className="item-shape-1" />
		              </div>
		            </div>
		            {/* statitics-item end */}
		            <div className="col-lg-4">
		              <div className="statitics-item mb-30">
		                <h6 className="title">Earning Total</h6>
		                <span className="stat-amount color-2">$90.00</span>
		                <div className="item-shape-2" />
		              </div>
		            </div>
		            {/* statitics-item end */}
		            <div className="col-lg-4">
		              <div className="statitics-item mb-30">
		                <h6 className="title">Pending Withdraw</h6>
		                <span className="stat-amount color-3">$35.00</span>
		                <div className="item-shape-3" />
		              </div>
		            </div>
		            {/* statitics-item end */}
		          </div>
		          <div className="revenue-statistics-area">
		            <h3 className="title">Revenue Statistics</h3>
		            <div className="revenue-statistics-chart-area">
		              <div className="revenue-statistics-chart-header">
		                <ul className="view-list-menu">
		                  <li><a href="#">Result</a></li>
		                  <li><a href="#">daily</a></li>
		                  <li><a href="#">monthly</a></li>
		                  <li><a href="#">yearly</a></li>
		                </ul>
		                <span>Jun - Nov <i className="fa fa-calendar-o" /></span>
		              </div>
		              <div className="revenue-statistics-chart"><canvas id="revenueChart" /></div>
		            </div>
		          </div>
		          {/* revenue-statistics-area end */}
		          {/* transaction-area start */}
		          <div className="transaction-area left-bottom-line-bg common-pd-bottom-3" style={{backgroundImage: 'url('+publicUrl+'assets/img/shape/pen.png)'}}>
		            <div className="container">
		              <div className="row justify-content-center">
		                <div className="col-lg-12">
		                  <div className="transaction-tab-area">
		                    <ul className="transaction-tab nav nav-tabs" id="myTab" role="tablist">
		                      <li className="nav-item btn">
		                        <a className="nav-link bottom-right-radius-0 active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Deposit</a>
		                      </li>
		                      <li className="nav-item btn m-0">
		                        <a className="nav-link bottom-left-radius-0" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Withdraw</a>
		                      </li>
		                    </ul>
		                    <div className="transaction-table tab-content" id="myTabContent">
		                      <div className="tab-pane deposit-tab fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
		                        <div className="table-responsive">
		                          <table className="table table-hover">
		                            <thead>
		                              <tr>
		                                <th colSpan={2} scope="col">Person</th>
		                                <th scope="col">Date</th>
		                                <th scope="col">Amount</th>
		                                <th scope="col">Currency</th>
		                                <th scope="col">Deposit</th>
		                              </tr>
		                            </thead>
		                            <tbody>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/01.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/02.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/03.png" }alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/04.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/01.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                            </tbody>
		                          </table>
		                        </div>
		                      </div>
		                      <div className="tab-pane withdraw-tab fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
		                        <div className="table-responsive">
		                          <table className="table table-hover">
		                            <thead>
		                              <tr>
		                                <th colSpan={2} scope="col">Person</th>
		                                <th scope="col">Date</th>
		                                <th scope="col">Amount</th>
		                                <th scope="col">Currency</th>
		                                <th scope="col">Deposit</th>
		                              </tr>
		                            </thead>
		                            <tbody>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/01.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/02.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/03.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/04.png"} alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                              <tr className="table-margin">
		                                <th />
		                              </tr>
		                              <tr>
		                                <th scope="row"><img src={publicUrl+"assets/img/transection/01.png" }alt="client" /></th>
		                                <td className="name">Emmett Steinkellner</td>
		                                <td>Oct 24,2018</td>
		                                <td>$9,00,000.00</td>
		                                <td>Dollar</td>
		                                <td>001 Days Ago</td>
		                              </tr>
		                            </tbody>
		                          </table>
		                        </div>
		                      </div>
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
		          {/* transaction-area end */}
		        </div>
		      </div>
		    </div>
		  </div>
		</div>


        }
}

export default UserPanel