import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class WorkInformation extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.workprocessing

    return <div className="work-area common-pd-2 bg-none">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center mg-top-180 invest-go-top">
                  <span className="common-icon-circle bg-smile-green"><img src={publicUrl+data.icon} alt="icon" /></span>
                  <h4><Link to={ data.url }>{ data.title }</Link></h4>
                  <p>You can open bank accounts entirely online, with no need for signatures or branch ... you can complete most banking tasks online—even opening your account....</p>
                  <Link className="btn btn-plus" to={ data.url }><i className="fa fa-plus" /></Link>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                	{ data.items.map( ( item, i )=> 
	                  <div key={ i } className="col-lg-6 col-md-6">
	                    <div className="single-work text-center invest-go-top">
	                      <span className="common-icon-circle bg-pink"><img src={ publicUrl+item.icon } alt="icon" /></span>
	                      <h4><Link to={ item.url } >{ item.title }</Link></h4>
	                      <p>{ item.content }</p>
	                      <Link className="btn btn-plus" to={ item.url }><i className="fa fa-plus" /></Link>
	                    </div>
	                  </div>
		            ) }

                  <div className="col-lg-12 mb-5 mb-mg-0">
                    <div className="single-input-wrap text-center text-lg-right">
                       <form method="GET">
		                  <input name="email" placeholder="Inter your email address" type="text" className="single-input" />
		                  <input type="submit" className="btn btn-basic" value="GO ON" />
		                  </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        }
}

export default WorkInformation